// Styles for Menus and Navs
// --------------------------------------------------
@import "../base/mixins";
@import "../radix/mixins";

@media (max-width: $screen-xs-max) {
  header {
    &.col-md-12 {
      padding: 0 0;
    }

    .navbar-fixed-top.oa-fullwidth {
      position: static;
    }

    div.oa-hidden {
      display: none;
    }
  }
}

.oa-navbar {
  .oa-site-banner-img {
    margin-left: 5px;
  }

  @media (max-width: $screen-sm-max) {
    div[class*="-mobile-menu"] {
        display: none;
    }
  }

  #breadcrumb-hint-img {
    max-width: none;
    height: 25px;
    position: absolute;
    top: -30px;
    left: 60px;
  }

  #breadcrumb-hint-text {
    width: 700px;
    position: absolute;
    top: -29px;
    left: 110px;
    font-family: 'Roboto Slab', serif;
    font-size: 18px;
    font-weight: bold;
  }

  #oa_breadcrumb {
    .dropdown-toggle {
      display: none;
    }

    .dropdown-menu {
      display: none;
    }

    > li {
      position: relative;

      &:first-child {
        a {
          padding-left: 0;
          position: static;
          top: 0;
          -webkit-transform: none;
          -ms-transform: none;
          transform: none;
        }
      }

      &:after {
        content: '' !important;
      }

      a {
        padding-top: 0;
        padding-left: 30px;
        text-shadow: none !important;
        @include vertical-align();
      }
    }

    .oa-breadcrumb-arrow {
      position: absolute;
      right: -10px;
      top: 0;
      width: 0;
      height: 0;
      z-index: 1000;
      border-top: 21px solid transparent;
      border-left: 10px solid #0da6f7;
      border-bottom: 21px solid transparent;
    }
  }

  .panel-panel {
    .panel-pane {
      padding-top: 0;
      padding-bottom: 0;
      margin-top: 0;
      margin-bottom: 0;

      &.pane-superfish-1 {
        display: block;

        @include breakpoint(md) {
          top: -99999em;
          background-color: $navbar-menu-bg;
          position: absolute;

          .fa {
            font-size: 24px;
            float: right;
            margin-top: 5px;
            color: $navbar-menu-color;
          }

          .sf-vertical {
            width: auto;

            > li {
              width: auto;
            }
          }

          ul {
            li {
              a {
                color: $navbar-menu-color;
                border-radius: 0;
                line-height: 38px;
                padding: 3px 20px;
                background-color: $navbar-menu-bg;

                &:hover, &:focus {
                  background-color: $navbar-inverse-link-hover-bg !important;
                  color: $navbar-inverse-link-hover-color;
                  text-decoration: none;

                  i {
                    color: $navbar-inverse-link-hover-color;
                  }
                }
              }

              &:hover, &.sfHover {
                > a {
                  background-color: #c1c1c1;
                  color: #fff;

                  i {
                    color: #fff;
                  }
                }
              }
            }

            div {
              &.sf-scroll-up, &.sf-scroll-dn {
                float: left;
                width: 100%;
                background-color: #fff;
                padding: 0.5em 0px;

                &:hover {
                  background-color: #c1c1c1;
                }
              }
            }

            .sf-uparrow,
            .sf-dnarrow,
            .sf-uparrow-disabled,
            .sf-dnarrow-disabled {
              margin: 0 auto;
              width: 10px;
              height: 10px;
              text-indent: -999em;
              overflow: hidden;
              background: url("../images/updn-arrows-superscroll.png") no-repeat 0 0;
            }

            .sf-dnarrow {
              background-position: 0 -100px;
            }

            .sf-uparrow {
              background-position: 0 0;
            }

            .sf-dnarrow-disabled {
              background-position: -10px -100px;
            }

            .sf-uparrow-disabled {
              background-position: -10px 0;
            }
          }
        }
      }

      .dl-menuwrapper {
        .dl-menu {
          height: 1000px;
          width: $mobile-menu-width;
          top: -5px;
          left: -275px;
          background-color: $gray;
          opacity: 1;
          -webkit-transform: none;
          transform: none;
          -webkit-backface-visibility: visible;
          backface-visibility: visible;
          overflow-x: hidden;

          &:after {
            content: "";
            display: block;
            width: 20px;
            height: 120%;
            position: absolute;
            left: 100%;
            top: -10%;
            z-index: 99;
            box-shadow: 0 0 10px rgba(0, 0, 0, 0.6);
          }

          > a {
            display: block;
            width: 100%;
            text-align: center;
            height: 71px;

            @media (min-width: $screen-sm-max) {
              height: 59px;
            }

            img {
              @include vertical-align();
            }
          }

          &.dl-menu-toggle {
            transition: none;
          }

          &.dl-animate-in-1 {
            animation-duration: 0.1s;
            animation-name: ImpMenuAnimIn;
            animation-timing-function: linear;
          }

          &.dl-animate-out-1 {
            animation-duration: 0.1s;
            animation-name: ImpMenuAnimOut;
            animation-timing-function: linear;
          }
        }

        .dl-curpage {
          background-color: #79777A;
        }

        > .dl-submenu {
          width: $mobile-menu-width;
          height: 100%;
          top: 61px;

          &.dl-animate-in-1 {
            animation-duration: 0;
            animation-name: ImpSubMenuAnimIn;
            animation-timing-function: linear;
          }

          &.dl-animate-out-1 {
            animation-duration: 0.1s;
            animation-name: ImpSubMenuAnimOut;
            animation-timing-function: linear;
          }
        }

        .dl-submenu {
          position: absolute;
          width: $mobile-menu-width;
        }

        ul {
          background-color: $gray;
        }

        li {
          a {
            font-size: 12px;
          }

          .dl-back a {
            text-transform: capitalize;
          }

          &.dl-back:after,
          > a:not(:only-child):after {
            font-family: FontAwesome;
            content: "\f105";
            color: #fff;
            font-size: 18px;
          }
        }

        button {
          &:hover,
          &.dl-active {
            background: transparent;
          }
        }
      }
    }
  }

  @media (max-width: $screen-sm-max) {
    #toolbar-touchpoint-button,
    .toolbar-button {
      display: none;
    }
  }

  #toolbar-menu-button,
  #toolbar-touchpoint-button,
  .toolbar-button {
    height: 39px;

    i {
      font-size: 25px;
      @include vertical-align();
    }
  }

  #toolbar-touchpoint-button i {
    color: #fff;
    font-size: 27px;
  }

  div.pane-oa-toolbar-oa-user-badge.user-mobile-menu {
    display: block;
  }

  .row:first-child {
    background-color: #fff;
  }
}

.dropdown-menu {
  border: none;
  border-radius: 0;
  box-shadow: none;
  padding-top: 0;
  padding-bottom: 0;

  ul {
    li {
      a {
        line-height: 38px;
      }
    }
  }
}

#navbar-administration {
  font-family: $font-family-sans-serif;
  color: $navbar-menu-color;
  line-height: 1;
  display: block;

  h3 {
    font-size: 16px;
    font-weight: bold;
    line-height: 1.1;
    color: $navbar-inverse-menu-color;
    background-color: $navbar-inverse-menu-bg;
    padding: 10px 15px;

    &.element-invisible {
      clip: auto;
      height: auto;
      overflow: visible;
      position: static !important;
    }

    i {
      font-size: 35px;
      display: block;
      float: left;
      margin-top: -10px;
      margin-right: 10px;
      color: $navbar-inverse-menu-color;

      &:hover {
        cursor: pointer;
      }
    }
  }

  a {
    font-size: 12px;
    margin-left: 4em;
    padding-left: 0;

    &.navbar-icon {
      &::before {
        background-image: none;
      }
    }
  }

  .navbar-box {
    a {
      border-left: 0;
    }

    i {
      float: left;
      display: block;
      font-size: 30px;
      margin-top: 4px;
      margin-left: 20px;

      &:hover {
        cursor: pointer;
      }
    }
  }

  .navbar-icon {
    &.navbar-handle {
      display: none;
    }
  }

  &.navbar-oriented {
    .navbar-tray {
      .navbar-toggle-orientation,
      .navbar-icon-toggle-horizontal,
      .navbar-icon-toggle-vertical {
        display: none !important;
      }
    }

    .navbar-tray-vertical {
      z-index: 1000;
      width: 240px;
      width: 24rem;
      border: none;

      &.navbar-active {
        left: auto;
        right: 0;
        bottom: 0;
        height: auto;
        overflow: auto;

        > .navbar-lining {
          top: 3px;
          bottom: 0;
          border-right: none;
          position: absolute;
          display: block;
          width: 100%;
          line-height: 1;
          background-color: $navbar-inverse-menu-color;
        }
      }

      .edit-shortcuts {
        display: none;
      }

      .navbar-menu {
        display: block;
        line-height: 1;

        li {
          border-top: none;
          line-height: 1;
    
          &:last-child {
            border-bottom: none;
          }
        }
    
        ul {
          margin-left: 1.5em;
          ul {
            border-top: none;
            border-bottom: none;
            background-color: $navbar-menu-bg;
            margin-left: 1.25em;
          }
        }
    
        a {
          color: $navbar-menu-color !important;
          padding: 1em 1.3333em 1em 0;
          display: block;
          text-decoration: none;
    
          &:hover {
            color: $navbar-menu-color;
            text-decoration: underline;
          }
        }
      }
    }
  }
}

#oa-user-badge {
  margin-bottom: 10px;

  .user-badge {
    font-weight: bold;

    img {
      margin: 0 7px 0 0;
      display: inline-block;
      border-radius: 50%;
    }

    span {
      display: inline;
      height: 30px;
      line-height: 30px;
      vertical-align: middle;
    }

    i {
      margin-top: -17px;
      font-size: 28px;
    }
  }

  .btn {
    padding-left: 5px;
    padding-right: 0;
  }

  .btn-group {
    &.open {
      .dropdown-toggle {
        box-shadow: none;
      }
    }
  }

  .dropdown-menu {
    min-width: 170px;
    top: 43px;
    z-index: 1005;

    ul {
      li {
        a {
          &:hover, &:focus {
            background-color: $navbar-inverse-link-hover-bg;
            background-image: none;
            color: $navbar-inverse-link-hover-color;
          }
        }
      }
    }
  }
}

@-webkit-keyframes ImpMenuAnimIn {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 1;
  }
}

@keyframes ImpMenuAnimIn {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 1;
  }
}

@-webkit-keyframes ImpSubMenuAnimIn {
  0% {
    opacity: 1;
  }
  100% {
     opacity: 1;
  }
}

@keyframes ImpSubMenuAnimIn {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 1;
  }
}

@-webkit-keyframes ImpMenuAnimOut {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 1;
  }
}

@keyframes ImpMenuAnimOut {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 1;
  }
}

@-webkit-keyframes ImpSubMenuAnimOut {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 0;
  }
}

@keyframes ImpSubMenuAnimOut {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 0;
  }
}
