// Styles for modal popups
// --------------------------------------------------
@import "../base/variables";

#modalBackdrop {
  background-color: #000 !important;
  opacity: 0.65 !important;
}

#modalContent {
  .ctools-modal-content {
    margin-left: auto;
    margin-right: auto;
    @include vertical-align();

    box-shadow: none !important;
    border-radius: 0;

    &.modal-forms-modal-content {
      font-family: $font-family-base;

    }

    form > div > .form-item {
      border-bottom-style: none;
    }

    .popups-container {
      border-radius: 0;

      .modal-header, .modal-content {
        border-radius: 0;
      }

      .modal-header {
        background-color: #E2E2E2;
        background-image: none;
        min-height: 40px;
        padding-top: 8px;

        .modal-title {
          color: #000;
          display: block;
          float: left;
          font-size: 16px;
          margin-left: 10px;
          max-width: 90%;
          white-space: normal;
          word-wrap: break-word; 
        }

        span.popups-close.close {
          display: block;
          background: none;
          text-indent: 12px;
          color: #E2E2E2;
          opacity: 1;
          padding-top: 1px;

          &:before {
            font-size: 16px;
            opacity: 1;
            color: $gray;
            font-family: FontAwesome;
            content: "\f00d";
          }
        }
      }

      .modal-content {
        border-style: none;
        box-shadow: none !important;
      }
    }
  }

  .panels-categories-description {
    height: 100%;

    .inner {
      position: relative;
      top: 50%;
      transform: translateY(-50%);
    }
  }

  #filter_widgets_text {
    height: 50px;
    font-size: 20px;
    margin: 20px 0 30px 0;
  }

  #edit-buttons {
    padding-top: 2em;
    border-top: 1px solid $legend-border-color;
    position: fixed;
    bottom: 0;
    right: 0;
  }

  .ctools-auto-submit-full-form .form-actions,
  #edit-buttons {
    max-width: 100%;
    width: 100%;
    padding-right: 30px;

    #edit-cancel-button {
      float: left;
      margin-left: 40px;
    }

    #edit-return {
      float: right;
    }

    .ajax-progress-throbber {
      margin-right: 10px;
    }
  }

  fieldset.widget-preview {
    margin-top: 0;

    .panel-body {
      height: 68vh;
      max-height: 68vh;
    }
  }

  .cke_contents {
    height: 39vh;
    max-height: 39vh;
  }

  #edit-field-basic-text-text {
    textarea {
      height: 46vh;
      max-height: 46vh;
    }

    .markItUp textarea {
      height: 43vh;
      max-height: 43vh;
    }
  }

  .ctools-auto-submit-full-form {
    float: left;
    width: 48%;
  }

  .ctools-auto-submit-full-form fieldset {
    width: 100%;
  }

  // Hide line displayed above chosen inputs.
  .chosen-choices {
    margin-top: 0;
  }
}
