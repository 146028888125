// Styles for Forms
// --------------------------------------------------

.compact-form-header {
  @include loginhdr;
  text-align: center;
}

.form-actions {
  background: none !important;
}

form {
  .desc-highlight {
    color: #d04437;
  }

  .supascript {
    font-size: 0.8em;
    vertical-align:top;
  }
}

#autosave-status {
  background-color: #555;
  padding: 10px 0;
  height: auto;
}

.chosen-fieldset-wrapper .chosen-container {
  width: 100% !important;
}

.select2-container {
  display: block;

  .select2-selection {
    height: auto !important;
    min-height: 34px;

    .select2-selection__rendered,
    .select2-selection__arrow {
      margin-top: 2px;
    }

    .select2-search__field {
      margin-top: 0;
    }
  }
}

#main .webform-client-form .container {
  padding-left: 15px;
  padding-right: 15px;

  textarea {
    resize: vertical;
  }
}

.webform-component-number {
  &.webform-container-inline label {
    display: inline-block;
  }

  label {
    display: block;
  }

  .form-control {
    width: auto;
  }
}

.webform-container-inline {
  .form-control {
    width: auto;
    display: inline-block;
  }

  select.form-control {
    width: 100%;
  }
}

form#webform-configure-form .webform-container-inline select.form-control {
  width: auto;
}
