// Page Layout
// -----------------------------------------------------------------------------
//
@import "../radix/mixins";
@import "../base/variables";

body {
  font-family: $font-family-base;

  h2 {
    font-size: 18px;
  }

  &.not-logged-in {
    background-image: url(/sites/all/themes/impetus/assets/images/login-screen-background.jpg);
    background-repeat: repeat;
    background-color: $login-bg;
    background-position: center;
    background-size: auto;

    #main-wrapper {
      background-color: transparent;

      #main {
        margin: 0 0;
        background-color: transparent;
      }
    }
  }

  @media (max-width: $screen-md-max) {
    #main {
      margin-left: 5px;
      margin-right: 5px;
    }
  }

  .ui-widget-overlay {
    position: fixed;
    @include modal-bg();
  }

  &.page-oa-files-upload {
    background-color: #fff;
  }

  .radix-layouts-sidebar {
    @media (max-width: $screen-sm-max) {
      display: none;
    }

    .pane-title {
      margin-top: 0;
    }

    .oa-pane {
      border-style: none;
    }
  }

  .radix-layouts-content .panel-panel-inner div:first-child h3 {
    margin-top: 0;
  }

  .pane-og-menu-single-menu.panel-pane {
    padding-bottom: 0;

    h2 {
      width: 100%;
      padding: 10px 15px;
      line-height: 32px;
      font-size: 18px;
      margin-bottom: 0;

      a {
        color: #fff;
      }
    }

    .pane-content {
      padding: 0;
    }

    .menu {
      li.dhtml-menu {
        &.dhtml-folder {
          &.expanded .dhtml-menu-icon,
          &.collapsed .dhtml-menu-icon,
          &.expanded::before,
          &.collapsed::before {
            content: '';
            background-image: none;
          }

          a.active {
            width: 100%;
            font-weight: bold;
          }
        }

        i {
          float: right;
          font-size: 30px;
          padding: 5px 15px 5px 10px;

          &:hover {
            cursor: pointer;
          }

          &.completion-indicator {
            font-size: 25px;
            margin-top: 3px;
            padding-right: 0;
            &.incomplete {
              color: #d3d3d3;
            }
            &.complete {
              color: $brand-success;
            }
          }
        }

        &.leaf i.completion-indicator {
          margin-right: 44px;
        }
      }
    }

    ul.menu > li > ul.menu {
      margin-left: 0;

      a {
        padding-left: 30px;
      }

      ul.menu a {
        padding-left: 50px;
      }
    }

    li {
      background-color: #fff;
      border-bottom: 2px solid;

      &:first-child {
        border-top: 2px solid;
      }

      &:last-child {
        border-bottom-style: none;
      }

      a {
        color: inherit;
        display: block;
        padding: 10px 15px;
        font-size: 18px;
      }

      li a {
        font-size: 16px;
      }
    }

    .menu-comments-new {
      margin-left: 10px;
      background-color: #c9243f;
      color: #ffffff;
      padding: 1px 7px 0px 7px;
      font-size: 12px;
      display: inline-block;
      border-radius: 3px;
    }
  }

  .ctools-collapsible-container {
    .ctools-toggle, .ctools-toggle-collapsed {
      background-image: none;
    }
  }

  table.sticky-header {
    z-index: 999;
  }
}

// we aren't using sidebar1 right now
.oa-site-layout-default {
  .oa-layout-sidebar1 {
    display: none;
  }
}

.ajax-progress-throbber,
.ajax-progress .throbber {
  background-image: none;
}

// Copy the fa, fa-spinner, and fa-spin styles to set up the throbber.
.ajax-progress-throbber:before {
  display: inline-block;
  font: normal normal normal 14px/1 FontAwesome;
  font-size: inherit;
  text-rendering: auto;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  content: "\f110";
  -webkit-animation: fa-spin 2s infinite linear;
  animation: fa-spin 2s infinite linear;
}
